import { FAIL, GET_LINE_MANAGERS, SUCCESS } from "../action_types";

const STATE_INIT = {
  isLoading: true,
  lineManagers: [],
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_LINE_MANAGERS:
      return { ...state, isLoading: true };
    case `${GET_LINE_MANAGERS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        lineManagers: [...payload],
      };
    case `${GET_LINE_MANAGERS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
