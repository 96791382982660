import { SUCCESS, FAIL, GET_BUSINESS_HOUR_DETAILS } from "../action_types";

const STATE_INIT = {
  isLoading: false,
  businessHourDetails: [],
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_BUSINESS_HOUR_DETAILS:
      return { ...state, isLoading: true };
    case `${GET_BUSINESS_HOUR_DETAILS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        businessHourDetails: [...payload],
      };
    case `${GET_BUSINESS_HOUR_DETAILS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
