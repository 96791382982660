import { GET_FIRE_CHECKS, SUCCESS, FAIL } from "../action_types";

const STATE_INIT = {
  isLoading: false,
  isUpdating: false,
  fireCheckPoints: [],
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_FIRE_CHECKS:
      return { ...state, isLoading: true };
    case `${GET_FIRE_CHECKS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        fireCheckPoints: [...payload],
      };
    case `${GET_FIRE_CHECKS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
