const secretKey = "holisto_secret_key";
const CryptoJS = require("crypto-js");

export const EncryptValue = (value) =>
  value ? CryptoJS.AES.encrypt(value, secretKey).toString() : "";

export const DecryptValue = (value) =>
  value
    ? CryptoJS.AES.decrypt(value, secretKey).toString(CryptoJS.enc.Utf8)
    : "";

export const setSessionStorage = (key, value) => {
  localStorage.setItem(key, EncryptValue(JSON.stringify(value)));
};

export const getSessionStorage = (key) => {
  const data = DecryptValue(localStorage.getItem(key));
  if (data) {
    return JSON.parse(data);
  }
  return data;
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, EncryptValue(value));
};

export const getLocalStorage = (key) => {
  const data = DecryptValue(localStorage.getItem(key));
  if (data) {
    return data;
  }
  return data;
};

// Convert file to base64 string
export const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function (event) {
      resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};
