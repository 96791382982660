import {
  GET_CONTRACTORS,
  GET_CONTRACTOR_SUPPLIER_DETAILS,
  SUCCESS,
  FAIL,
} from "../action_types";

const STATE_INIT = {
  isLoading: false,
  isUpdating: false,
  isDetailsLoading: true,
  contractors: [],
  contractorDetails: [],
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_CONTRACTORS:
      return { ...state, isLoading: true };
    case `${GET_CONTRACTORS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        contractors: [...payload],
      };
    case `${GET_CONTRACTORS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    case GET_CONTRACTOR_SUPPLIER_DETAILS:
      return { ...state, isDetailsLoading: true };
    case `${GET_CONTRACTOR_SUPPLIER_DETAILS}_${SUCCESS}`:
      return {
        ...state,
        isDetailsLoading: false,
        contractorDetails: [...payload],
      };
    case `${GET_CONTRACTOR_SUPPLIER_DETAILS}_${FAIL}`:
      return { ...state, isDetailsLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
