import {
  GET_USERS,
  GET_CEO,
  GET_GENERAL_MANAGERS,
  GET_REGIONAL_MANAGERS,
  GET_MANAGERS_PERMISSIONS,
  SUCCESS,
  FAIL,
  GET_USER_ROLES,
  GET_DEACTIVATED_USERS,
} from "../action_types";

const STATE_INIT = {
  isLoading: true,
  isUpdating: true,
  gmLoading: true,
  rmLoading: true,
  isRolesLoading: true,
  users: [],
  userRoles: [],
  ceo: [],
  generalMs: [],
  regionalManagers: [],
  managersPermissions: [],
  deactivatedUsers: [],
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_USERS:
      return { ...state, isLoading: true };
    case `${GET_USERS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        users: [...payload],
      };
    case `${GET_USERS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    case GET_USER_ROLES:
      return { ...state, isRolesLoading: true };
    case `${GET_USER_ROLES}_${SUCCESS}`:
      return {
        ...state,
        isRolesLoading: false,
        userRoles: [...payload],
      };
    case `${GET_USER_ROLES}_${FAIL}`:
      return { ...state, isRolesLoading: false, error: { ...payload } };

    case GET_DEACTIVATED_USERS:
      return { ...state, isRolesLoading: true };
    case `${GET_DEACTIVATED_USERS}_${SUCCESS}`:
      return {
        ...state,
        isRolesLoading: false,
        deactivatedUsers: [...payload],
      };
    case `${GET_DEACTIVATED_USERS}_${FAIL}`:
      return { ...state, isRolesLoading: false, error: { ...payload } };

    case GET_CEO:
      return { ...state, isLoading: true };
    case `${GET_CEO}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        ceo: [...payload],
      };
    case `${GET_CEO}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    case GET_GENERAL_MANAGERS:
      return { ...state, gmLoading: true };
    case `${GET_GENERAL_MANAGERS}_${SUCCESS}`:
      return {
        ...state,
        gmLoading: false,
        generalMs: [...payload],
      };
    case `${GET_GENERAL_MANAGERS}_${FAIL}`:
      return { ...state, gmLoading: false, error: { ...payload } };

    case GET_REGIONAL_MANAGERS:
      return { ...state, rmLoading: true };
    case `${GET_REGIONAL_MANAGERS}_${SUCCESS}`:
      return {
        ...state,
        rmLoading: false,
        regionalManagers: [...payload],
      };
    case `${GET_REGIONAL_MANAGERS}_${FAIL}`:
      return { ...state, rmLoading: false, error: { ...payload } };

    case GET_MANAGERS_PERMISSIONS:
      return { ...state, isLoading: true };
    case `${GET_MANAGERS_PERMISSIONS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        managersPermissions: [...payload],
      };
    case `${GET_MANAGERS_PERMISSIONS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
