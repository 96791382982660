import { GET_COMPLIANCE_RANGE, SUCCESS, FAIL } from "../action_types";

const STATE_INIT = {
  isLoading: false,
  isUpdating: false,
  ranges: [],
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_COMPLIANCE_RANGE:
      return { ...state, isLoading: true };
    case `${GET_COMPLIANCE_RANGE}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        ranges: [...payload],
      };
    case `${GET_COMPLIANCE_RANGE}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
