import { SUCCESS, FAIL, GET_TASK_GROUPS } from "../action_types";

const STATE_INIT = {
  isLoading: false,
  taskGroups: [],
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_TASK_GROUPS:
      return { ...state, isLoading: true };
    case `${GET_TASK_GROUPS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        taskGroups: [...payload],
      };
    case `${GET_TASK_GROUPS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
