import {
  GET_SERVICE_LINE_CATEGORIES,
  SUCCESS,
  FAIL,
  GET_SERVICE_LINE_ITEMS,
  GET_SERVICE_LINE_ITEMS_REPORT,
} from "../action_types";

const STATE_INIT = {
  isCategoriesLoading: true,
  serviceLineCategories: [],
  isItemsLoading: true,
  serviceLineItems: [],
  isReportLoading: true,
  reportData: [],
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_SERVICE_LINE_CATEGORIES:
      return { ...state, isCategoriesLoading: true };
    case `${GET_SERVICE_LINE_CATEGORIES}_${SUCCESS}`:
      return {
        ...state,
        isCategoriesLoading: false,
        serviceLineCategories: payload,
      };
    case `${GET_SERVICE_LINE_CATEGORIES}_${FAIL}`:
      return { ...state, isCategoriesLoading: false, error: { ...payload } };

    case GET_SERVICE_LINE_ITEMS:
      return { ...state, isItemsLoading: true };
    case `${GET_SERVICE_LINE_ITEMS}_${SUCCESS}`:
      return {
        ...state,
        isItemsLoading: false,
        serviceLineItems: payload,
      };
    case `${GET_SERVICE_LINE_ITEMS}_${FAIL}`:
      return { ...state, isItemsLoading: false, error: { ...payload } };

    case GET_SERVICE_LINE_ITEMS_REPORT:
      return { ...state, isReportLoading: true };
    case `${GET_SERVICE_LINE_ITEMS_REPORT}_${SUCCESS}`:
      return {
        ...state,
        isReportLoading: false,
        reportData: payload,
      };
    case `${GET_SERVICE_LINE_ITEMS_REPORT}_${FAIL}`:
      return { ...state, isReportLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
