import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import loadable from "@loadable/component";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "./infrastructure/assets/scss/theme.scss";

import store from "./application/services";
import Loader from "./infrastructure/components/Loader";

const container = document.getElementById("root");
const root = createRoot(container);

const AppComponent = loadable(() => import("./App"), {
  // eslint-disable-next-line react/jsx-filename-extension
  fallback: (
    <div className="d-flex align-items-center vh-100">
      <Loader />
    </div>
  ),
});

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AppComponent />
    </BrowserRouter>
  </Provider>
);
