import {
  SUCCESS,
  FAIL,
  ORG_LEVEL_PROGRESS_SUMMARY,
  OUTLET_DRILL_DOWN_REPORT,
} from "../action_types";

const STATE_INIT = {
  isLoading: false,
  orgLevelSummary: {},
  outletReport: {},
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case ORG_LEVEL_PROGRESS_SUMMARY:
      return { ...state, isLoading: true };
    case `${ORG_LEVEL_PROGRESS_SUMMARY}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        orgLevelSummary: { ...payload },
      };
    case `${ORG_LEVEL_PROGRESS_SUMMARY}_${FAIL}`:
      return {
        ...state,
        isLoading: false,
        orgLevelSummary: {},
        error: { ...payload },
      };

    case OUTLET_DRILL_DOWN_REPORT:
      return { ...state, isLoading: true };
    case `${OUTLET_DRILL_DOWN_REPORT}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        outletReport: { ...payload },
      };
    case `${OUTLET_DRILL_DOWN_REPORT}_${FAIL}`:
      return {
        ...state,
        isLoading: false,
        orgLevelSummary: {},
        error: { ...payload },
      };

    default:
      return state;
  }
};
