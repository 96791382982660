import { SUCCESS, FAIL, GET_SHIFTS } from "../action_types";

const STATE_INIT = {
  isLoading: false,
  shifts: [],
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_SHIFTS:
      return { ...state, isLoading: true };
    case `${GET_SHIFTS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        shifts: [...payload],
      };
    case `${GET_SHIFTS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
