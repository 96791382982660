import {
  GET_GENERAL_MANAGER_STATS,
  GET_ORGANISATION_CONFIGURATIONS,
  SUCCESS,
  FAIL,
} from "../action_types";

const STATE_INIT = {
  isStatsLoading: true,
  isConfigurationsLoading: true,
  stats: {},
  organisationConfigurations: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_GENERAL_MANAGER_STATS:
      return { ...state, isStatsLoading: true };
    case `${GET_GENERAL_MANAGER_STATS}_${SUCCESS}`:
      return {
        ...state,
        isStatsLoading: false,
        stats: { ...payload },
      };
    case `${GET_GENERAL_MANAGER_STATS}_${FAIL}`:
      return { ...state, isStatsLoading: false, error: { ...payload } };

    case GET_ORGANISATION_CONFIGURATIONS:
      return { ...state, isConfigurationsLoading: true };
    case `${GET_ORGANISATION_CONFIGURATIONS}_${SUCCESS}`:
      return {
        ...state,
        isConfigurationsLoading: false,
        organisationConfigurations: { ...payload },
      };
    case `${GET_ORGANISATION_CONFIGURATIONS}_${FAIL}`:
      return {
        ...state,
        isConfigurationsLoading: false,
        error: { ...payload },
      };

    default:
      return state;
  }
};
