import { GET_FOOD_TEMP_CONFIGS, SUCCESS, FAIL } from "../action_types";

const STATE_INIT = {
  isLoading: false,
  foodTemperatures: {},
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_FOOD_TEMP_CONFIGS:
      return { ...state, isLoading: true };
    case `${GET_FOOD_TEMP_CONFIGS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        foodTemperatures: { ...payload },
      };
    case `${GET_FOOD_TEMP_CONFIGS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
