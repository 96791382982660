import {
  GET_FOOD_ITEMS,
  GET_FOOD_CATEGORIES,
  GET_HOT_FOOD_CATEGORIES,
  GET_CHILLED_FOOD_CATEGORIES,
  GET_CFTC_FOOD_CATEGORIES,
  GET_CHILLED_FOOD_ITEMS,
  GET_HOT_FOOD_ITEMS,
  GET_CFTC_FOOD_ITEMS,
  SUCCESS,
  FAIL,
  GET_CFTC_FOOD_HANDLINE_TYPE,
  GET_CFTC_FOOD_HANDLINE_TYPE_FOR_TAB,
} from "../action_types";

const STATE_INIT = {
  isLoading: false,
  isCategoriesLoading: false,
  isUpdating: false,
  foods: [],
  isHotFoodsLoading: true,
  isCftcFoodLoading: true,
  isChilledFoodsLoading: true,
  hotFoods: [],
  chilledFoods: [],
  cftcFoods: [],
  foodCategories: [],
  hotFoodCategories: [],
  chilledFoodCategories: [],
  cftcFoodCategories: [],
  error: {},
  cftcFoodHandlingType: [],
  isFoodHandlingTypesLoading: true,
  cftcFoodHandlingTypeForTab: [],
  isFoodHandlingTypesForTabLoading: true,
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_FOOD_ITEMS:
      return { ...state, isLoading: true };
    case `${GET_FOOD_ITEMS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        foods: [...payload],
      };
    case `${GET_FOOD_ITEMS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    case GET_CHILLED_FOOD_ITEMS:
      return { ...state, isChilledFoodsLoading: true };
    case `${GET_CHILLED_FOOD_ITEMS}_${SUCCESS}`:
      return {
        ...state,
        isChilledFoodsLoading: false,
        chilledFoods: [...payload],
      };
    case `${GET_CHILLED_FOOD_ITEMS}_${FAIL}`:
      return { ...state, isChilledFoodsLoading: false, error: { ...payload } };

    case GET_HOT_FOOD_ITEMS:
      return { ...state, isHotFoodsLoading: true };
    case `${GET_HOT_FOOD_ITEMS}_${SUCCESS}`:
      return {
        ...state,
        isHotFoodsLoading: false,
        hotFoods: [...payload],
      };
    case `${GET_HOT_FOOD_ITEMS}_${FAIL}`:
      return { ...state, isHotFoodsLoading: false, error: { ...payload } };

    case GET_CFTC_FOOD_ITEMS:
      return { ...state, isCftcFoodLoading: true };
    case `${GET_CFTC_FOOD_ITEMS}_${SUCCESS}`:
      return {
        ...state,
        isCftcFoodLoading: false,
        cftcFoods: [...payload],
      };
    case `${GET_CFTC_FOOD_ITEMS}_${FAIL}`:
      return { ...state, isCftcFoodLoading: false, error: { ...payload } };

    case GET_FOOD_CATEGORIES:
      return { ...state, isCategoriesLoading: true };
    case `${GET_FOOD_CATEGORIES}_${SUCCESS}`:
      return {
        ...state,
        isCategoriesLoading: false,
        foodCategories: [...payload],
      };
    case `${GET_FOOD_CATEGORIES}_${FAIL}`:
      return { ...state, isCategoriesLoading: false, error: { ...payload } };

    case GET_HOT_FOOD_CATEGORIES:
      return { ...state, isCategoriesLoading: true };
    case `${GET_HOT_FOOD_CATEGORIES}_${SUCCESS}`:
      return {
        ...state,
        isCategoriesLoading: false,
        hotFoodCategories: [...payload],
      };
    case `${GET_HOT_FOOD_CATEGORIES}_${FAIL}`:
      return { ...state, isCategoriesLoading: false, error: { ...payload } };

    case GET_CHILLED_FOOD_CATEGORIES:
      return { ...state, isCategoriesLoading: true };
    case `${GET_CHILLED_FOOD_CATEGORIES}_${SUCCESS}`:
      return {
        ...state,
        isCategoriesLoading: false,
        chilledFoodCategories: [...payload],
      };
    case `${GET_CHILLED_FOOD_CATEGORIES}_${FAIL}`:
      return { ...state, isCategoriesLoading: false, error: { ...payload } };

    case GET_CFTC_FOOD_CATEGORIES:
      return { ...state, isCategoriesLoading: true };
    case `${GET_CFTC_FOOD_CATEGORIES}_${SUCCESS}`:
      return {
        ...state,
        isCategoriesLoading: false,
        cftcFoodCategories: [...payload],
      };
    case `${GET_CFTC_FOOD_CATEGORIES}_${FAIL}`:
      return { ...state, isCategoriesLoading: false, error: { ...payload } };

    case GET_CFTC_FOOD_HANDLINE_TYPE:
      return { ...state, isFoodHandlingTypesLoading: true };
    case `${GET_CFTC_FOOD_HANDLINE_TYPE}_${SUCCESS}`:
      return {
        ...state,
        cftcFoodHandlingType: [...payload],
        isFoodHandlingTypesLoading: false,
      };
    case `${GET_CFTC_FOOD_HANDLINE_TYPE}_${FAIL}`:
      return {
        ...state,
        cftcFoodHandlingType: [],
        error: { ...payload },
        isFoodHandlingTypesLoading: false,
      };
    case GET_CFTC_FOOD_HANDLINE_TYPE_FOR_TAB:
      return { ...state, isFoodHandlingTypesForTabLoading: true };
    case `${GET_CFTC_FOOD_HANDLINE_TYPE_FOR_TAB}_${SUCCESS}`:
      return {
        ...state,
        cftcFoodHandlingTypeForTab: [...payload],
        isFoodHandlingTypesForTabLoading: false,
      };
    case `${GET_CFTC_FOOD_HANDLINE_TYPE_FOR_TAB}_${FAIL}`:
      return {
        ...state,
        cftcFoodHandlingTypeForTab: [],
        error: { ...payload },
        isFoodHandlingTypesForTabLoading: false,
      };

    default:
      return state;
  }
};
