import {
  GET_ASSETS,
  SUCCESS,
  FAIL,
  GET_ASSET_REASON_LIST,
} from "../action_types";

const STATE_INIT = {
  isLoading: true,
  isUpdating: true,
  assets: [],
  freezers: [],
  notInUseReasons: [],
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_ASSETS:
      return { ...state, isLoading: true };
    case `${GET_ASSETS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        assets: [...payload],
        freezers: [...payload],
      };
    case `${GET_ASSETS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    case GET_ASSET_REASON_LIST:
      return { ...state, isLoading: true };
    case `${GET_ASSET_REASON_LIST}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        notInUseReasons: [...payload],
      };
    case `${GET_ASSET_REASON_LIST}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
