import {
  GET_OUTLETS,
  GET_OUTLET_DETAILS_BY_USER_ID,
  GET_OUTLET_DETAILS_BY_OUTLET_ID,
  SUCCESS,
  FAIL,
} from "../action_types";

const STATE_INIT = {
  isLoading: false,
  isUpdating: false,
  outlets: [],
  outletDetails: [],
  outletDetailsById: [],
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_OUTLETS:
      return { ...state, isLoading: true };
    case `${GET_OUTLETS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        outlets: [...payload],
      };
    case `${GET_OUTLETS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    case GET_OUTLET_DETAILS_BY_USER_ID:
      return { ...state, isLoading: true };
    case `${GET_OUTLET_DETAILS_BY_USER_ID}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        outletDetails: [...payload],
      };
    case `${GET_OUTLET_DETAILS_BY_USER_ID}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    case GET_OUTLET_DETAILS_BY_OUTLET_ID:
      return { ...state, isLoading: true };
    case `${GET_OUTLET_DETAILS_BY_OUTLET_ID}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        outletDetailsById: [...payload],
      };
    case `${GET_OUTLET_DETAILS_BY_OUTLET_ID}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
