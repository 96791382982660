import React from "react";
import "./loader.css";

function Loader() {
  return (
    // <div className="loder-container">
    <span className="loader"></span>
    // </div>
  );
}

export default Loader;
