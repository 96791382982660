// DEV URL
const devURL = `https://dev.api.holisto.net/`;

// UAT
const uatURL = `https://uat.api.holisto.net/`;

// PROD URL
const prodURL = `https://prod.api.holisto.net/`;

const ENV = process.env?.REACT_APP_ENV;
export let baseURL = "";
export let uiVersion = "";

if (ENV === "development") {
  baseURL = devURL;
  uiVersion = "dev-2";
} else if (ENV === "uat") {
  baseURL = uatURL;
  uiVersion = "uat-12";
} else if (ENV === "prod") {
  baseURL = prodURL;
  uiVersion = "prod-15";
}

export const MASTER_PERMISSION = 2048;
