import {
  SUCCESS,
  FAIL,
  GET_CHECKLISTS,
  GET_CHECKLIST_ID,
  GET_ESCALATION_PERSONS_LIST,
  GET_CHECKLISTS_REPORT,
} from "../action_types";

const STATE_INIT = {
  isLoading: false,
  checklists: [],
  checklistDetails: [],
  escalationPersons: [],
  isPersonsLoading: true,
  isDetailsLoading: true,
  isReportsLoading: true,
  reportsData: {},
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_CHECKLISTS:
      return { ...state, isLoading: true };
    case `${GET_CHECKLISTS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        checklists: [...payload],
      };
    case `${GET_CHECKLISTS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    case GET_CHECKLIST_ID:
      return { ...state, isDetailsLoading: true };
    case `${GET_CHECKLIST_ID}_${SUCCESS}`:
      return {
        ...state,
        isDetailsLoading: false,
        checklistDetails: [...payload],
      };
    case `${GET_CHECKLIST_ID}_${FAIL}`:
      return { ...state, isDetailsLoading: false, error: { ...payload } };

    case GET_ESCALATION_PERSONS_LIST:
      return { ...state, isPersonsLoading: true };
    case `${GET_ESCALATION_PERSONS_LIST}_${SUCCESS}`:
      return {
        ...state,
        isPersonsLoading: false,
        escalationPersons: [...payload],
      };
    case `${GET_ESCALATION_PERSONS_LIST}_${FAIL}`:
      return { ...state, isPersonsLoading: false, error: { ...payload } };

    case GET_CHECKLISTS_REPORT:
      return { ...state, isReportsLoading: true };
    case `${GET_CHECKLISTS_REPORT}_${SUCCESS}`:
      return {
        ...state,
        isReportsLoading: false,
        reportsData: payload || [],
      };
    case `${GET_CHECKLISTS_REPORT}_${FAIL}`:
      return { ...state, isReportsLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
