import {
  SUCCESS,
  FAIL,
  GET_AUDITS,
  GET_AUDIT_BY_ID,
  GET_HISTORY_AUDITS,
  GET_AUDIT_CATEGORIES,
} from "../action_types";

const STATE_INIT = {
  isLoading: false,
  isDetailsLoading: true,
  audits: [],
  auditDetails: [],
  performedAudits: [],
  auditCategories: [],
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_AUDITS:
      return { ...state, isLoading: true };
    case `${GET_AUDITS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        audits: [...payload],
      };
    case `${GET_AUDITS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    case GET_AUDIT_BY_ID:
      return { ...state, isDetailsLoading: true };
    case `${GET_AUDIT_BY_ID}_${SUCCESS}`:
      return {
        ...state,
        isDetailsLoading: false,
        auditDetails: [...payload],
      };
    case `${GET_AUDIT_BY_ID}_${FAIL}`:
      return { ...state, isDetailsLoading: false, error: { ...payload } };

    case GET_HISTORY_AUDITS:
      return { ...state, isLoading: true };
    case `${GET_HISTORY_AUDITS}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        performedAudits: [...payload],
      };
    case `${GET_HISTORY_AUDITS}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    case GET_AUDIT_CATEGORIES:
      return { ...state, isLoading: true };
    case `${GET_AUDIT_CATEGORIES}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        auditCategories: [...payload],
      };
    case `${GET_AUDIT_CATEGORIES}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    default:
      return state;
  }
};
