import {
  GET_ORGANISATION,
  GET_ORGANISATION_LOGO,
  SUCCESS,
  FAIL,
} from "../action_types";

const STATE_INIT = {
  isLoading: false,
  isUpdating: false,
  orgDetails: [],
  orgLogo: "",
  error: {},
};

export default (state = STATE_INIT, { type, payload }) => {
  switch (type) {
    case GET_ORGANISATION:
      return { ...state, isLoading: true };
    case `${GET_ORGANISATION}_${SUCCESS}`:
      return {
        ...state,
        isLoading: false,
        orgDetails: [...payload],
      };
    case `${GET_ORGANISATION}_${FAIL}`:
      return { ...state, isLoading: false, error: { ...payload } };

    case GET_ORGANISATION_LOGO:
      return {
        ...state,
        orgLogo: payload,
      };

    default:
      return state;
  }
};
