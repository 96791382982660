import {
  legacy_createStore as createStore,
  compose,
  applyMiddleware,
} from "redux";
import axios from "axios";
import { thunk } from "redux-thunk";
import axiosMiddleware from "redux-axios-middleware";
// import logger from "redux-logger";

import { baseURL, uiVersion } from "../config";
import { getSessionStorage } from "../../infrastructure/utils/helpers";

import rootReducer from "./reducers";

const outletDetails = getSessionStorage("outletDetails")
  ? JSON.parse(getSessionStorage("outletDetails"))
  : {};

export const client = axios.create({
  baseURL,
  responseType: "json",
  params: {
    uiversion: uiVersion,
  },
});

client.interceptors.request.use(
  async (request) => {
    request.params.reqOutletId = outletDetails?.id ? outletDetails?.id : null;
    return request;
  },
  (error) => {
    console.log("error", error);
  }
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let middlewares = [thunk, axiosMiddleware(client)];

// if (process.env.NODE_EN === "production")
//   middlewares = [...middlewares, logger];

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
